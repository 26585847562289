<template>
  <nuxt-link class="blog__item card no-decoration card--np" :to="`/blog/${data.slug}`">
    <div class="card__image" v-if="data?.thumbnail?.data" >
      <StrapiImageHazy
        loading="lazy" 
        :placeholder="thumbnail.placeholder"
        :src="getFlattenedStrapiImageLocation(thumbnail)"
        provider="strapi"
      />
    </div>
    <div class="card__content">
      <h5 class="heading-5 mb-05">{{ data.title }}</h5>
      <span class="subinfo" :class="hasCategories ? 'mb-1' : 'mb-2'">
        <Icon name="ant-design:calendar-outlined" class="icon--md"/> Gepubliceerd op: {{ publishedAt }}
      </span>
      <CategoryHolder :items="categories" v-if="hasCategories" class="mb-2"/>
      <div class="rendered" v-html="$md.render(data.content || '')"></div>
    </div>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type BlogArticle from "@/typings/strapi/objects/blogArticle";
import type Tag from '@/typings/items/tag';

const props = defineProps({
  data: {
    type: Object as PropType<BlogArticle>,
    required: true
  }
})

const dayjs = useDayjs();
const publishedAt = computed(() => dayjs(props.data.publishedAt).format('D MMMM YYYY'));
const thumbnail = computed(() => flattenObject(props.data.thumbnail));
const categories = computed(() => {
  const catgrs = flattenCollection(props.data.categories).map<Tag>(c => ({
    id: c.id,
    title: c.title,
    type: 'default'
  }));

  if (props.data?.private) {
    catgrs.unshift({
      title: 'Privé',
      type: 'exclusive',
      id: 0
    });
  }

  return catgrs;
});
const hasCategories = computed(() => categories.value && categories.value.length > 0);
</script>
